<template>
    <div class="news-detail">
        <nav-bar class="nav-bar" id="titleArea" ref="nav"></nav-bar>
        <!-- <div class="home-banner">
            <div class="banner-wrapper">
                <div class="banner"></div>
            </div>
        </div> -->
        <div class="news-content" id="middleArea">
            <div class="banner"></div>
            <div class="jj">
                <div class="jj-content">
                    <div class="logo">
                        <img src="../../assets/img/aboutUs/logo.png"/>
                    </div>
                    <div class="info">
                        <div class="title">创新院简介</div>
                        <div class="detail">
                            <p>2014年6月，合肥市人民政府与中科院合肥物质科学研究院签订《战略合作协议》，共建中科院合肥技术创新工程院、中科院（合肥）技术创新工程院有限公司，采用“两块牌子一套人马”的企业化运作方式推进科技成果的转移、落地。</p>
                            <p>自成立以来，中科院合肥创新院紧密围绕科技成果转化，努力构建“技术孵化、技术转化、企业孵化、创业投资”四位一体的创新机制，建立起创新链、产业链、人才链、资本链、服务链五大要素紧密融合的创新创业生态环境。</p>
                        </div>
                    </div>
                </div>
                <div class="floor">
                    <img src="../../assets/img/aboutUs/floor.png"/>
                </div>
                <div class="jj-top"></div>
                <div class="jj-bottom">
                    <img src="../../assets/img/aboutUs/yy.png"/>
                </div>
            </div>
            <div class="company">
                <div class="team">
                    <img src="../../assets/img/aboutUs/logo2.png"/>
                </div>
                <div class="info">
                    <div class="title">中心简介</div>
                    <div class="detail">
                        <div v-html="htmlContent"></div>
                    </div>
                </div>
            </div>
            <div class="contact">
                <div class="title">联系我们</div>
                <div class="map">
                    <!-- <img src="../../assets/img/aboutUs/qiu.png"/> -->
                </div>
                <div class="info">
                    <div class="item">
                        <div class="name">公司地址</div>
                        <div>{{ this.about.address }}</div>
                    </div>
                    <div class="item">
                        <div class="name">联系邮箱</div>
                        <div>{{ this.about.email }}</div>
                    </div>
                    <div class="item">
                        <div class="name">客服热线</div>
                        <div>客服热线：{{ this.about.phone }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="news-content" id="middleArea"> -->
            <!-- <div class="title text-over">{{ about.title }}</div> -->
            <!-- <div class="desc" v-html='this.about.content'>
            </div>
            <div class="title">联系我们</div>
            <div class="area">
                <img src="../../assets/img/aboutUs/map.png" />
                <div class="area-right">
					<img src="../../assets/img/common/logo.png" class="bar-logo" />
                    <div class="company">
                        <p>安徽中科中云教育科技有限公司</p>
                        <p>{{ this.about.address }}</p>
                    </div>
                    <div class="company">
                        <p style="white-space: pre-wrap;">联系邮箱
                            <br /><strong>{{ this.about.email }}</strong>
                        </p>
                        <p style="white-space: pre-wrap;">
                            客服热线
                            <br /><strong>{{ this.about.phone }}</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div> -->
        <bottom-bar class="news-bottom" />
    </div>
</template>
<script>
import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import { NewsAbout } from '@/api/news'
import { getInfo } from '@/api/login'

import NavBar from '../../components/content/navBar/NavBar'
import BottomBar from '../../components/content/bottomBar/BottomBar'
import MapBar from '../../components/MapBar.vue'

import { getToken, setToken, removeToken } from '@/utils/cookies.js'

export default {
    name: 'NewsDetail',
    components: {
        NavBar,
        BottomBar,
        MapBar
    },
    data() {
        return {
            users: {},//用户信息            
            about: {},//关于我们
			htmlContent:'<p>&nbsp;&nbsp;&nbsp;&nbsp;中科院合肥创新院心理健康研究中心(以下简称“中心”) 隶属于中科院合肥创新院。中心是集“心理学相关教育、课程、管理、服务与研究”一体的机构。为区县心理健康教育工作提供完整版解决方案，主要包括: <br/>&nbsp;&nbsp;&nbsp;&nbsp;1.开足开齐心理健康课程----提供心理课程平台、心理健康相关教材读本；<br/>&nbsp;&nbsp;&nbsp;&nbsp;2.学生心理筛查与干预----心理测评、心理档案、心理监测、心理干预；<br/>&nbsp;&nbsp;&nbsp;&nbsp;3.师资队伍建设----教师、班主任培训、心理咨询师培训；<br/>&nbsp;&nbsp;&nbsp;&nbsp;4.教学资源建设建设----区县心理健康相关资源建设；<br/>&nbsp;&nbsp;&nbsp;&nbsp;5.家校协同----家校共育培训;<br/>&nbsp;&nbsp;&nbsp;&nbsp;中心依托省内外多所著名高校的学者教授和安徽省教育厅心理健康专家委员会组成的师资团队，已在省内拥有大量成功案例,中心与国内众多高新技术企业密切合作，对所拥有的产品进行全方位考评与升级，力争为教育局、学校提供良好的产品和优质的服务。</p>'
        }
    },
    created() {
        //用户信息
        this.userInfo();
        //详细信息
        this.onAboutInfo();
    },
    methods: {
        //用户信息
        userInfo() {
            var token = getToken();
            if (token) {
                getInfo(token).then(response => {
                    if (response.status == 200) {
                        this.users = response.data;
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                })
            }
        },
        //详细信息
        onAboutInfo() {
            this.listLoading = true
            this.id = this.$route.params.id;
            NewsAbout('').then(response => {
                if (response) {
                    if (response.status == 200) {
                        this.about = response.data;
                    } else {
                        MessageBox.alert(response.message, "错误", {
                            confirmButtonText: '确定',
                            type: 'error'
                        })
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                }
            })
        }
    },
    mounted() {
        this.$refs.nav.mdd = 6;
        localStorage.setItem("mdd", 6);
        const titleHeight = document.getElementById('titleArea').clientHeight;
        const middleArea = document.getElementById('middleArea');
        const mheight = document.documentElement.clientHeight;
        middleArea.style.minHeight = (Number(mheight) - (Number(titleHeight) + 50)) + 'px';
    },
}
</script>

<style scoped lang="less">
.news-detail {
    min-width: 1300px;
    background-color: #fff;

    .nav-bar {
        .bar-icon {
            border-radius: 50%;
            vertical-align: -4px;
            margin-right: 7px;
            width: 1.05rem;
            height: 1.05rem;
        }
    }
    .news-content{
        .banner{
            background:url(../../assets/img/aboutUs/banner1.png) no-repeat;
            width:100%;
            height:268px;
            background-size: cover;
            color: #fff;
            text-align: center;
            line-height: 268px;
            font-size: 2.25rem;
        }
        .jj{
            position: relative;
            .jj-content{
                box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.10);
                padding-left: 48px;
                padding-right: 51px;
                padding: 49px 51px 48px;
                display: flex;
                align-items: center;
                position: absolute;
                top: 12.6rem;
                left: 9.1rem;
                right: 35rem;
                z-index: 3;
                background-color: #fff;

                .logo{
                    width: 10.35rem;
                    height: 9.56rem;
                    img{
                        width:100%;
                        height:100%;
                    }
                }
                .info{
                    flex: 1;
                    padding-left: 2.4rem;
                    .title{
                        color: #303133;
                        font-weight: 600;
                        margin-bottom: 31px;
                        font-size: 2rem;
                    }
                    .detail{
                        color: #6C6F75;
                        font-size: 0.95rem;
                        line-height: 1.85em;
                    }
                }
            }
            .floor{
                width: 29.4rem;
                height: 32rem;
                position: absolute;
                right: 9.1rem;
                top: 6.2rem;
                z-index:2;
                img{
                    width:100%;
                    height:100%;
                    object-fit: cover;
                }
            }
            .jj-top{
                height:25rem;
            }
            .jj-bottom{
                background-color: #F3F5F7;
                position: relative;
                height: 18rem;
                img{
                    opacity: 0.4;
                    width: 45rem;
                    height: 3.9rem;
                    position:absolute;
                    left: 11rem;
                    bottom:0;
                }
            }
        }
        .company{
            background-color: rgba(62, 171, 255,0.1);
            display: flex;
            align-items: center;
            padding:4.7rem 14rem;
            .team{
                width: 28.1rem;
                height:28.1rem;
                img{
                    width:100%;
                    height:100%;
                }
            }
            .info{
                    flex: 1;
                    padding-left: 7.3rem;
                    .title{
                        color: #303133;
                        font-weight: 600;
                        margin-bottom: 31px;
                        font-size: 2rem;
                    }
                    .detail{
                        color: #303133;
                        font-size:0.95rem;
                        line-height: 1.85em;
                    }
                }
        }
        .contact{
            //padding:5.5rem 9.1rem;
            padding:5.5rem 15.5rem;
            .title{
                color: #303133;
                font-weight: 600;
                margin-bottom: 45px;
                font-size: 2rem;
                text-align: center;
            }
            .map{
                background:url(../../assets/img/aboutUs/map3.png) no-repeat;
                width:100%;
                height:37rem;
                //background-size: cover;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 36.5rem;
                    height: 12.6rem;
                }
            }
            .info{
                margin-top: 34px;
                display: flex;
                justify-content: space-between;
                .item{
                    color: #6C6F75;
                    font-size: 0.95rem;
                    line-height: 1.8em;
                    .name{
                        color: #1D1E20;
                        font-size: 1rem;
                        font-weight: 600;
                        margin-bottom: 15px;
                    }
                }              
            }
        }
    }
}
</style>
8:30-9:30 